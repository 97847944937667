<template>
  <div class="tips">
    <el-dialog
      class="dialog"
      title=""
      :visible="dialogVisibles"
      width="30%"
      :before-close="clear"
      :close-on-click-modal="false"
    >
      <div class="elform">
        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :rules="rule"
          ref="formLabelAlign"
          :model="formLabelAlign"
        >
          <el-form-item label="选择意向">
            <el-radio v-model="formLabelAlign.intention" label="0"
              >求购</el-radio
            >
            <el-radio v-model="formLabelAlign.intention" label="1"
              >出售</el-radio
            >
          </el-form-item>
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="formLabelAlign.title"
              placeholder="添加标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="describe">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="添加描述"
              v-model="formLabelAlign.describe"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="" class="fconf">
            <el-button v-if="this.$store.state.home.announce" @click="sur(formLabelAlign)">发布</el-button>
              <el-button v-else @click="sur(formLabelAlign)">重新提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Public from "./public.vue";
import { Message } from "element-ui";
import informationApi from "../../../api/informationApi";
export default {
  data() {
    return {
      labelPosition: "right",
      rule: {
        title: [{ required: true, message: "请添加标题" }],
        describe: [{ required: true, message: "请添加描述" }],
      },
      formLabelAlign: {
        describe: null,
        intention:"0" ,
        title: null,
      },
      totaldialog: false,
    };
  },

  components: {
    Public,
  },
  props: {
    dialogVisibles: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    parentHandleclick(e){
    
       this.formLabelAlign = e 
       this.formLabelAlign.intention=this.formLabelAlign.intention.toString()
  
    },
    clear() {
      this.formLabelAlign.describe="",
      this.formLabelAlign.title="",
      this.$emit("clear", false);
    },
    sur(formLabelAlign) {
      this.$refs.formLabelAlign.validate((valid) => {
        if (valid) {
          if (this.$store.state.home.announce) {
            informationApi.addInformationSquare(this.formLabelAlign, (res) => {
           
              if (res.data.code == 200) {
                Message.success("发布信息成功");
                setTimeout(() => {
                  this.$parent.getInformationList();
                }, 500);
                this.$emit("clear", false);
                this.formLabelAlign.describe = "";
                this.formLabelAlign.title = "";
              } else {
                Message.error(res.data.message);
              }
            });
          }
          else{
           
           informationApi.resubmit(this.formLabelAlign, (res) => {
             
              if (res.data.code == 200) {
                Message.success("重新提交成功");
                setTimeout(() => {
                  this.$parent.getInformationList();
                }, 500);
                this.$emit("clear", false);
                this.formLabelAlign.describe = "";
                this.formLabelAlign.title = "";
              } else {
                Message.error(res.data.message);
              }
            });
          }
        }
      });
      // this.$emit("suree", false);
      // encodeURIComponent(JSON.stringify(this.param))
      // encodeURIComponent(JSON.stringify(this.formLabelAlign))
      // 发布信息
    },
    // clears(bool) {
    //   this.totaldialog = bool;
    // },
    // surees(bool) {
    //   this.totaldialog = bool;
    // },
    // openProduct() {
    //   this.totaldialog = true;
    // },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  ::v-deep .el-dialog {
    min-width: 477px !important;
  }
  .dialog {
    text-align: center;
    .elform .fconf .el-button {
      width: 300px;
      height: 40px;
      background: #e94f55;
      border-radius: 5px;
      // margin-top: 30px;
      color: white;
      margin-left: -100px;
    }
    .buttons .el-button {
      width: 300px;
      // margin-left: -120px;
    }
  }
}
</style>