<template>
  <div class="good">
    <div class="coheader">
      <div>
        <span class="cospan">审核状态：</span>
        <el-select v-model="values" placeholder="请选择"  @change="approval">
          <el-option
            v-for="item in auditoptions"
            :key="item.values"
            :label="item.label"
            :value="item.values"
          >
          </el-option>
        </el-select>
      </div>
          <div class="yi">
        <span class="cospan">禁用状态：</span>
        <el-select v-model="value" placeholder="请选择" @change="forbidden">
          <el-option
            v-for="item in forbiddenoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="yi">
        <span class="cospan">意向：</span>
        <el-select v-model="valueinten" placeholder="请选择" @change="purpose">
          <el-option
            v-for="item in intentionoptions"
            :key="item.valueinten"
            :label="item.label"
            :value="item.valueinten"
          >
          </el-option>
        </el-select>
      </div>
      <div class="cocommit" @click="open">
          +  发布
      </div>
    </div>
    <div class="comiddle">
        <div class="cocontent" v-for="(item,index) in informationdata " :key="index">
            <!-- <div>
              <img  class="coimage" src="../../assets/7.jpg">
            </div> -->
            <div>
                <div class="cofirst">
                  <div v-if="item.disable" ><span class="publicstate" > 已禁用</span>
                   <span class="publicstate cause" @click="lookcause(item)"> 查看原因 </span></div>
                <div v-else>
                       <span class="publicstate" v-show=" item.approvalstate==0"> 待审核</span>
                 <span class="publicstate" v-show=" item.approvalstate==1"> 已通过</span>
                 <span class="publicstate" v-show="item.approvalstate==2"> 已拒绝 </span>
                  <span class="publicstate cause" v-show="item.approvalstate==2" @click="lookcause(item)"> 查看原因 </span>
                  <el-button type="danger" v-show="item.approvalstate==2" @click="editopen(item.uuid)" plain>编辑</el-button>
           <el-button type="danger" v-show="item.approvalstate==2" @click="delmessage(item.uuid)" plain>删除</el-button>
                  </div> 
                   <Goodsure
      :addDialog="addDialog"
      :title="title"
      @del="del"
      @addsure="addsure"
      :resultmessag="resultmessag"
    ></Goodsure>
                  </div>
              
                <div  class="cosecond"> 
                   <router-link  :to="{path:'/exchangeDetial',query:{id
                :item.uuid}}"> 
                   {{item.title}} 
                   </router-link>
                </div>
                
                <div class="cothird">
                     {{item.describe}}
                </div>
            </div>
            
        </div>
     
  
       
    </div>

 
   <Person :dialogVisibles="dialogVisibles" ref="mychild" @clear="clear" @suree="suree"></Person>
   <!-- <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="  prev, pager, next"
      :total="totalCount"
    >
    </el-pagination> -->

  <Pagination  class="el-pagination " :num="totalCount" :limit="pageSize" :totalPageCount="totalPageCount"></Pagination>

  </div>
</template>
<script>

import Person from "./entre/personlog.vue"
import informationApi from "../../api/informationApi"
import Goodsure from "./entre/cause.vue";
import goodsApi from "../../api/goodsApi";
import Pagination from "@/components/prePage/pagination.vue";
import { Message ,MessageBox} from "element-ui";
export default {
  data() {
    return {
      editform:{},
        resultmessag: [],
      addDialog: false,
        title: "",
      information:{
  
}, 
      pageNum:1,
      pageSize:5,
      totalCount:0,
      totalPageCount:0,
      informationdata:[],
      
          dialogVisibles:false,
          // 审核
      auditoptions: [
        {
          values: null,
          label: "全部",
        },
        {
          values: "0",
          label: "待审核",
        },
        {
          values: "1",
          label: "已通过",
        },
        {
          values: "2",
          label: "已拒绝",
        },
      ],
      values: "全部",
      // 禁用
          forbiddenoptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "true",
          label: "是",
        },
        {
          value: "false",
          label: "否",
        }
      ],
      value: "全部",
      valueinten:"全部",
      // 意向
       intentionoptions: [
        {
          valueinten: null,
          label: "全部",
        },
        {
          valueinten: "0",
          label: "求购",
        },
        {
          valueinten: "1",
          label: "出售",
        }
      ],
    }
  },
  components:{
      Person,
       Goodsure,
       Pagination
  },
  mounted(){
 this.getInformationList()
  },
  methods: {

                change(even){
 this.pageNum = even
   this.getInformationList()
    },
    // 删除信息
    delmessage(id){
// informationApi.delInformationSquare()
    MessageBox.confirm('确认删除商品吗?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then((_) => {
          informationApi.delInformationSquare({ id: id }, (res) => {
        
            if (res.data.code != 200) {
              Message.error(res.data.message);
            } else {
              Message({
                message: "删除成功",
                type: "success",
              });
              this.getInformationList();
            }
          });
        })
        .catch((_) => {
           Message({
            type: 'info',
            message: '已取消删除'
          });    
        });
    },
    
    // 查看原因
    lookcause(item){ 
      if(item.disable){
            informationApi.getInformationDisableReason({id: item.uuid}, (res) => {
         
        
          if(res.data.code==200){
            this.addDialog = true; 
             this.title = "禁用原因";
          this.resultmessag = res.data.result;
          }
          else{
             Message.error(res.data.message);
          }
         
          
        });
      }
      else{
            informationApi.getInformationRefuseReason({id: item.uuid}, (res) => {
         
        
          if(res.data.code==200){
            this.addDialog = true; 
             this.title = "拒绝原因";
          this.resultmessag = res.data.result;
          }
          else{
             Message.error(res.data.message);
          }
         
          
        });
      }
      
    },
     // 查看原因
    del(bool) {
      this.addDialog = bool;
    },
    addsure(bool) {
      this.addDialog = bool;
      this.totaldialog = true;
    },
    // 获取当前用户的信息列表
     getInformationList(){
         informationApi.getInformationList({page:this.pageNum,size:this.pageSize},this.information,res=>{
         
           if(res.data.code==200){
              
                this.informationdata =res.data.result.data 
                  this.totalCount = res.data.result.totalCount;
                  this.totalPageCount = res.data.result.totalPageCount;
           }
        
         })
     },
    //  发布
    open(){
     this.$store.commit("initannounce", true);
    this.dialogVisibles=true
    },
    // 编辑
    editopen(id){
         informationApi.getInformationInfo({id:id},res=>{
         
           if(res.data.code==200){
             this.dialogVisibles=true
                this.editform = res.data.result 
                this.$refs.mychild.parentHandleclick(this.editform)
  this.$store.commit("initannounce", false);
  
           }
         })
        
    },
       clear(bool){
    this.dialogVisibles = bool
     
    },
    suree(bool){
    this.dialogVisibles = bool
    },
    // 审核状态：
    approval(val){
    
      this.pageNum = 1
     this.information.approvalstate = val
      this.getInformationList()
    },
    // 禁用状态：
forbidden(val){

 this.information.disable = val
  this.pageNum = 1
  this.getInformationList()
},
    // 意向：
purpose(val){
   this.information.intention = val
   this.pageNum = 1
    this.getInformationList()

},
      //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
       this.getInformationList()
    },
  //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
       this.getInformationList()
    },


  },
};
</script>

<style  lang = 'scss' scoped>
.good {
  /* width: 100%; */
  /* width: 925px; */

.coheader {
    width: 100%;
  display: flex;
  flex-direction: row;
  ::v-deep .el-select {
    width: 100px;
  }
::v-deep .el-select .el-input.is-focus .el-input__inner {
  width: 100px;
  border-color: #409eff;
}
::v-deep .el-select .el-input__inner {
  width: 92px;
  cursor: pointer;
  padding-right: 35px;
}

.el-select .el-input .el-input__inner,
.el-cascader .el-input .el-input__inner {
  background: #fdfdfd;
  border-radius: 5px;
  border: 1px solid #cccccc;
  text-align: right;
  background-color: rgba(0, 0, 0, 0);
}
.cospan {
  width: 90px;
  height: 25px;
  font-size: 14px;

  font-weight: 600;
  color: #333333;
  line-height: 25px;
}
.yi{
    margin-left: 50px;
}
.cocommit{
width: 101px;
height: 35px;
background: rgb(233, 75, 83);
border-radius: 5px;
color: white;
font-size: 14px;
text-align: center;
line-height: 35px;
margin-left: 30px;
margin-top: 3px;
cursor: pointer;
}
}

.comiddle{
  /* width: 60%; */
  min-height: 500px;
   .cocontent{
    background: #f1f0f0;
    padding: 5px 0px;
    margin-top: 10px;
    border-radius: 10px;
    .coimage{
border-radius: 10px;
margin-left: 20px;
margin-top: 40px;
}
/* div{
    border: 1px solid black;
} */
.cofirst{

height: 25px;
font-size: 12px;
font-weight: 600;
color: #E94F55;
line-height: 25px;
/* margin-top: 20px; */
text-align: right;
padding-right: 10px;
.el-button{
     padding: 8px 10px;
}
}
.cosecond{
  cursor: pointer;
width: 393px;
white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;
/* height: 40px; */
font-size: 16px;
font-weight: 600;
color: rgba(0, 0, 0, 0.87);
/* line-height: 40px; */
margin-left: 20px;

a{
  color: #333333;
  text-decoration: none;
}
}
.cothird{

width: 693px;
/* height: 60px; */
font-size: 14px;
font-weight: 400;
color: rgba(0, 0, 0, 0.56);
line-height: 20px;
margin-left: 20px;
margin-top: 10px;
white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;
}
}

}

.pagination{
    margin-top: 30px;
}

}
.el-pagination {
   width: 100%;
  margin: 20px auto;
}
.publicstate{

  margin-right: 20px;

}
.cause{
  cursor: pointer;
  color: #409eff;
}
</style>