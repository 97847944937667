import baseApi from './baseApi.js';
const informationApi = {
	// 发布信息
	addInformationSquare(params,callback){
		baseApi.post('center/informationsquare/addInformationSquare',params,callback)
	},
	//获取当前用户的信息列表
	getInformationList(params,data,callback){
		baseApi.get('center/informationsquare/getInformationList?page='+params.page + '&size=' + params.size,data,callback)
	},
	// 修改信息
	setInformationSqueare(params,callback){
		baseApi.post('center/informationsquare/setInformationSqueare'+params,callback)
	},
	// 重新提交
	
	resubmit(params,callback){
		baseApi.post('center/informationsquare/resubmit',params,callback)
	},
	// 删除信息
	delInformationSquare(params,callback){
		baseApi.post('center/informationsquare/delInformationSquare?id='+params.id,null,callback)
	},
	// 信息广场获取信息列表
	getInformationExhibitList(params,callback){
		baseApi.get('center/informationsquare/getInformationExhibitList?title='+params.title+'&intention='+params.intention+'&page='+params.page+'&size='+params.size,null,callback)
	},
	// 获取回复详情
	getReply(params,callback){
		baseApi.get('center/reply/getReply?informationId='+params.informationId+'&page='+params.page+'&size='+params.size,null,callback)
	},

	// 回复
	// 信息广场添加回复
	addReply(params,callback){
		baseApi.post('center/reply/addReply',params,callback)
	},
		// 信息广场二级回复
		addSecondReply(params,callback){
			baseApi.post('center/reply/addSecondReply',params,callback)
		},
		// 
		// 信息广场获取信息详情
		getInformationDetail(params,callback){
			baseApi.get('center/informationsquare/getInformationDetail?id='+params.id,null,callback)
		},
		// 查询信息详情（修改用）
		getInformationInfo(params,callback){
			baseApi.get('center/informationsquare/getInformationInfo?id='+params.id,null,callback)
		},
		// 获取信息拒绝原因
		getInformationRefuseReason(params,callback){
			baseApi.get('center/platformfeedback/getInformationRefuseReason?id='+params.id,null,callback)
		   },
		//    获取信息禁用原因
		getInformationDisableReason(params,callback){
			baseApi.get('center/platformfeedback/getInformationDisableReason?id='+params.id,null,callback)
		   },
		//  获取当前信息收藏状态
		getCollectRecordStateInformation(params,callback){
			baseApi.get('center/collectrecord/getCollectRecordStateInformation?sourceId='+params.sourceId,null,callback)
		   },
		   //  删除信息收藏
		   delCollectRecordInformation(params,callback){
			baseApi.post('center/collectrecord/delCollectRecordInformation?sourceId='+params.sourceId,null,callback)
		   },
		   //  添加信息收藏
		   addCollectRecordInformation(params,callback){
			baseApi.post('center/collectrecord/addCollectRecordInformation?sourceId='+params.sourceId,null,callback)
		   },
}
//抛出api 
export default informationApi